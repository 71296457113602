.react-credit-card__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  
    .react-credit-card {
      width: 320px;
      height: 200px;
      border-radius: 12px;
      position: relative;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      background: linear-gradient(135deg, #667eea, #764ba2);
  
      &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
        z-index: 0;
      }
  
      &__logo {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 60px;
      }
  
      &__number {
        position: absolute;
        top: 80px;
        left: 20px;
        font-size: 1.4rem;
        font-family: "Courier New", Courier, monospace;
        color: #fff;
      }
  
      &__name {
        position: absolute;
        bottom: 20px;
        left: 20px;
        font-size: 1rem;
        color: #fff;
        font-family: Arial, sans-serif;
      }
  
      &__expiry {
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 1rem;
        color: #fff;
  
        .expiry-label {
          font-size: 0.8rem;
          margin-right: 5px;
        }
      }
  
      &__stripe {
        position: absolute;
        top: 30px;
        left: 0;
        right: 0;
        height: 30px;
        background-color: #333;
      }
  
      &__cvc {
        position: absolute;
        top: 70px;
        right: 20px;
        font-size: 1rem;
        color: #fff;
      }
    }
  }
  