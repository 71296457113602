.model-details-container {
    padding: 20px;
  }
  
  .header-bar {
    background-color: #1976d2;
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .model-card {
    width: 300px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .metrics {
    margin-top: 10px;
  }
  
  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .crown {
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  
  @media (max-width: 768px) {
    .model-card {
      width: 100%; 
    }
    .feature-item div h3 {
      font-size: 20px;
    }
    .crown {
      width: 50px;
      height: 50px;
      margin-right: 20px;

    }
  }
  