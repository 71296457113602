.list-item {
  list-style: none;
  margin-left: -30px;
  width: 125%;
  margin-bottom: 10px;

  @media (max-width: 768px) { 
    width: 110%;
  }

  @media (max-width: 480px) { 
    width: 105%;
  }
}

.submenu-enter {
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.submenu-enter-active {
  max-height: 500px; /* Adjust this value as needed */
}

.submenu-exit {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 300ms ease-in-out;
}

.submenu-exit-active {
  max-height: 0;
}