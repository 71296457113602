@import "../../scss/default/color_variable";
.cs-pricing_table.cs-style1 {
  position: relative;
  background: linear-gradient(267.18deg, #161616 0%, #080808 100%);
  border-radius: 15px;
  overflow: hidden;
  padding: 0 45px;
  &::before {
    content: '';
    position: absolute;
    right: -55px;
    top: -55px;
    height: 135px;
    width: 135px;
    background: $accent;
    filter: blur(125px);
    transition: all 0.4s ease;
    opacity: 0;
  }
  .cs-pricing_title {
    font-size: 30px;
    padding: 30px 0 30px;
    border-bottom: 1px solid #302E2E;
    margin: 0;
  }
  .cs-price {
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
    margin-bottom: 5px;
    h3 {
      margin: 0;
      font-size: 38px;
    }
    span {
      position: relative;
      bottom: 6px;
    }
  }
  .cs-pricing_info {
    border-bottom: 1px solid #302E2E;
    padding-bottom: 23px;
  }
  .cs-pricing_feature {
    padding: 35px 0 25px;
    border-bottom: 1px solid #302E2E;
    li {
      position: relative;
      padding-left: 35px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    .cs-feature_icon {
      position: absolute;
      left: 0;
      top: 4px;
      display: flex;
      font-size: 20px;
    }
  }
  .cs-pricing_btn_wrap {
    padding: 28px 0;
  }
  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

@media screen and (max-width: 991px) {
  .cs-pricing_table.cs-style1 {
    .cs-pricing_title {
      font-size: 26px;
    }
    padding: 0 30px;
  }
}

.cs-pricing_title {
  position: relative;
  display: flex;
  flex-wrap: wrap; /* Ensures content adapts to small screens */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.cs-discount-box {
  display: inline-block;
  margin-left: 10px;
  margin-top: 5px; /* Adjust for small screens */
  padding: 6px 12px;
  background: #6a0dad; /* Purple color */
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  animation: pulse 1.5s infinite;
  white-space: nowrap; /* Prevents text wrapping */
}

@media (max-width: 768px) {
  .cs-pricing_title {
    flex-direction: column; /* Stack the title and discount box */
  }

  .cs-discount-box {
    margin-left: 0;
    margin-top: 8px;
    font-size: 12px; /* Smaller font for better fit */
    padding: 5px 10px;
  }
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(106, 13, 173, 0.6);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(106, 13, 173, 0.8);
  }
}
