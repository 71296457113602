/* src/components/PaymentMethodForm.scss */

.payment-form {
    /* Example: Add padding or custom styles */
    padding: 20px;
    
    .form-group {
      margin-bottom: 15px;
    }
  
    .form-control {
      border-radius: 5px;
      padding: 10px;
    }
  
    .btn-primary {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
    }
  }
  