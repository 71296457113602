@import "default/color_variable";
@import "default/typography";
@import "common/general";
@import "common/slider";
@import "common/video-modal";
@import "common/sidebar";


.slick-slide {
    margin-bottom: 20px;
}


.error-bar {
    margin-top: 10px;
    padding: 10px;
    background-color: #f44336;
    color: white;
    border-radius: 10px;
    text-align: center;
}

.p-tabview-nav {
    padding: 20px 60px;
    @media (max-width: 400px) {
        padding: 20px 30px;
    }
}


*,
::after,
::before {
    overflow: visible;
}

.statsHeader {
    background: #212129;
    border-radius: 20px;
    padding: 10px 20px;
}

.statsBody {
    background: #212129;
    border-radius: 20px;
    padding: 15px 20px;
}

.statsTable {
    width: 100%;
    color: #9c9ca3;
    border-collapse: collapse;
    font-family: 'DM Sans';
}

.statsTable th,
.statsTable td {
    border-bottom: 1px solid #494949;
    padding: 8px;
    text-align: center;
}

.lowestRange {
    color: #f13535 !important;
    font-weight: 600;
}

.midRange {
    color: #ef7e33 !important;
    font-weight: 600;
}

.averageRange {
    color: #f1ef35 !important;
    font-weight: 600;
}

.bestRange {
    color: #3cc244 !important;
    font-weight: 600;
}

.statsBody tr {
    transition: 0.3s;
}

.statsBody tr:hover {
    background: #7c7c7c41;
    border-radius: 5px;
}

// .reactTabView .p-tabview-nav-container {
//     width: 100%;
//     margin: 0 auto 50px auto;
//     align-items: center;
//     justify-content: center;

//     @media (max-width: 600px) {
//         width: 100%;
//         margin-left: 0;
//     }
// }

.cs-filter_menu.cs-style1 li:focus {
    border-bottom: 2px solid #ff4a17 !important;
}

// .p-tabview .p-tabview-nav li .p-tabview-nav-link {
//     border: solid #999696 !important;
//     border-width: 0 0 1px 0 !important;
//     border-color: transparent transparent #999696 transparent !important;
//     padding: 0.75rem 1.25rem;
//     border-top-right-radius: 6px;
//     border-top-left-radius: 6px;
//     transition: box-shadow 0.2s;
//     margin: 0 0 -1px 0;
//     font-family: 'Poppins';
//     font-weight: 300;
// }

.cs-filter_menu.cs-style1 li:not(:last-child) {
    margin-right: 0px !important;
}

.p-unselectable-text {
    padding: 4px;
}

ul {
    list-style-type: none !important;
}

.cs-filter_menu.cs-style1 li {
    padding-right: 20px;
}

// .reactTabPanel .p-tabview-title::before {
//     content: '';
//     height: 9px;
//     width: 9px;
//     position: absolute;
//     left: 50%;
//     margin-left: -5px;
//     border-radius: 50%;
//     background-color: var(--accent);
//     bottom: -5px;
//     transition: all 0.4s ease;
//     transform: scale(0);
// }

// .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-title::before {
//     transform: scale(1);
// }

.statsContainer {
    border: 1px solid #fff;
    border-radius: 20px;
    padding: 20px 0 40px 0;
}

// @media (max-width: 768px) {
//     .reactTabView2 .p-tabview .p-tabview-nav li .p-tabview-nav-link {
//         font-size: 12px;
//     }

//     .reactTabView2 .p-tabview .p-tabview-nav li .p-tabview-nav-link {
//         font-size: 15px;
//     }

//     .statsContainer {
//         width: 95%;
//         margin: 0 auto;
//     }
// }

// .reactTabView2 .p-tabview-nav-container {
//     width: 215px;
//     margin: 0 auto 50px auto;
// }


.cs-page_heading.cs-style1 {
    height: auto !important;
    padding: 130px 0 25px !important;
}

.cs-font_50 {
    font-size: 26px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    text-transform: uppercase !important;
    margin-bottom: 0 !important;
    @media (max-width: 320px) {
        font-size: 20px !important;
    }
}

.authMainContainer {
    display: grid; 
    grid-template-columns: auto auto;
    gap: 25px;
    @media (max-width: 376px) {
        grid-template-columns: auto;
    }
}

.SideBarNav {
    width: 100%;
}
.SideBarNav li {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 5px 0;
    cursor: pointer
}

.SideBarNav li.list-item {
    list-style: none;
    margin-left: -30px;
    width: 125%;
    margin-bottom: 10px;

    @media (max-width: 991px) { 
        width: 104%;
      }
    
    @media (max-width: 480px) { 
    width: 110%;
    }
}


.SideBarNav li:hover {
    background-color: #a9a9a917;
    border-radius: 5px;
}

.AuthContent {
    width: calc(100% - 250px); 
    margin-left: 250px;
    @media (max-width: 991px) {
        width: 100%;
        margin-left: 0;
    }
}

.ProfileDropdown {
    padding: 10px;
}
.ProfileDropdown:hover {
    background-color: rgba(169, 169, 169, 0.0901960784);
}

.cs-form_field {
    border: 2px solid rgb(255 255 255 / 34%) ;
}

.ProfileImageContainer img {
    border-radius: 1000px;
}

.ProfileContainer {
    padding: 20px 100px;
    @media (max-width: 768px) {
        padding: 20px 5px;
    }
}

.ProfileImage {
    justify-content: end;
    @media (max-width: 768px) {
        justify-content: center !important;
    }
}

.ProfileBtnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 768px) {
        flex-direction: row;
        gap: 10px;
        padding-right: 0;
        padding-top: 15px;
    }
}

.ProfileBtn {
    width: 175px;
    height: 45px;
    border-radius: .25rem !important;
    @media (max-width: 768px) {
        width: 160px !important;
    }
}

.table-bordered>:not(caption)>*>* {
    border: none;
}

/* ------------- ScrollBar ------------- */

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #dddddddd;
}

::-webkit-scrollbar-thumb {
    background-color: #FF4A17;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #d5370b;
}

.fixed-bottom {
  position: fixed;
  bottom: 15px;
  width: 100%;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.logout-button {
  width: 200px;
  border-radius: 1rem;
}

/* Custom horizontal scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  height: 6px; /* Adjust the height to reduce the scrollbar thickness */
}



.custom-scrollbar::-webkit-scrollbar-thumb {
//   background: #888; /* Scrollbar thumb color */
  border-radius: 3px;
}

.custom-tooltip {
    color: white;
    background-color: rgb(40, 39, 39);
    border-radius: 20px;
    margin-right: 20px;
    padding: 10px
  }

.text-centered svg {

}
  