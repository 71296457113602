/* ReferralProgramButton.css */

/* Basic button shape and style */
.referral-button {
  position: relative;
  cursor: pointer;
  background-color: #8b00ff;
  color: #fff;
  border: none;
  border-radius: 9999px; /* Pill shape by default */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  /* By default, let's set it up for the "join" mode on large screens */
  padding: 0 16px;
  height: 50px;
  margin-top: 5px;
}

/* On hover, darken color a bit */
.referral-button:hover {
  background-color: #7a00e6;
}

/* =========================
   JOIN MODE - Not joined
   =========================*/

/* 
  By default, on large screens, 
  show text & (optional) static stars
*/
.join-content-desktop {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Just for demonstration, we can style the star color/size. */
.join-content-desktop .star {
  color: #ffd700;
  font-size: 0.9rem;
}

/* 
  On small screens, .join-content-desktop is hidden,
  and we show .join-content-mobile => circle w/ rotating stars
*/
.join-content-mobile {
  display: none;
  position: relative;
}

/* By default, a .star inside mobile might be smaller */
.join-content-mobile .star {
  color: #ffd700;
  font-size: 0.7rem;
}

/* 
  The rotating container that spins all child stars
*/
.rotating-stars {
  width: 50px;
  height: 50px;
  position: relative;
  animation: spinStars 2.5s linear infinite; /* spin speed here */
}

/* Keyframes for spinning the entire container of stars */
@keyframes spinStars {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* 
  Position each star in a circle.
  We'll rely on the parent's rotation to move them around in a circle.
  The translate(15px) moves each star away from center, 
  so they appear on a ring.
*/
.star1,
.star2,
.star3 {
  position: absolute;
  top: 50%;
  left: 50%;
  /* Move them to center, 
     then rotate them around the center, 
     then translate outward. 
     The second rotate( ) "counter-rotates" the star 
     so it remains upright if you prefer. */
}

.star1 {
  transform: translate(-50%, -50%) rotate(0deg) translate(15px) rotate(0deg);
}
.star2 {
  transform: translate(-50%, -50%) rotate(120deg) translate(15px) rotate(-120deg);
}
.star3 {
  transform: translate(-50%, -50%) rotate(240deg) translate(15px) rotate(-240deg);
}

/* 
  Media queries to hide desktop content on small screens
  and hide mobile rotating stars on large screens.
*/
@media (max-width: 760px) {
  /* Make button a 50×50 circle only */
  .referral-button.join-mode {
    width: 50px;
    padding: 0;
  }

  .join-content-desktop {
    display: none; /* hide full text on small screens */
  }
  .join-content-mobile {
    display: block; /* show rotating stars */
  }
}

/* =========================
   CREDITS MODE - Already joined
   =========================*/

.credits-mode {
  position: relative; 
  width: 50px; /* default */
  height: 50px;
  transition: width 0.3s ease-in-out;
}

/* 
   The container for label + number 
   pinned to the right inside the button
*/
.credits-inner {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%); 
  white-space: nowrap;
  display: flex;
  align-items: center;
}

/* Label is hidden off to the right until hover on large screens */
.credits-mode .credits-label {
  margin-right: 4px;
  opacity: 0;
  transform: translateX(70%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* The numeric part is always visible */
.credits-mode .credits-count {
  z-index: 2;
}

/* On large screens, expand on hover so we can see "Credits: XX" */
@media (min-width: 761px) {
  .credits-mode:hover {
    width: 130px;
  }
  .credits-mode:hover .credits-label {
    opacity: 1;
    transform: translateX(0%);
    padding-right: 10px;
  }
}

/* 
   On small screens, keep it 50×50 circle 
   and hide the "Credits:" label
*/
@media (max-width: 760px) {
  .credits-mode:hover {
    width: 50px;
  }
  .credits-mode .credits-label {
    display: none;
  }
}
