/* src/components/PlanSelectionModal.scss */

.PlanModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.PlanModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  color: white;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  padding-bottom: 50px;
}

.close-button {
  background: transparent;
  border: none;
  color: rgb(65, 62, 62);
  font-size: 1.5rem;
  cursor: pointer;
}

.plans-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.plan-box {
  background: #2c2c2c;
  border-radius: 8px;
  flex: 1 1 calc(50% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.plan-box h3 {
  margin-top: 0;
  padding-bottom: 20px;
}

.subscribe-button {
  background: #ff4a17;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.subscribe-button:hover {
  background: #e04313;
}

.error-bar {
  background-color: #ff4a17;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Responsive styles for widths 720px or less */
@media (max-width: 725px) {
  .PlanModal {
    width: 90%;
    max-width: none;
    padding: 20px;
  }

  .modal-header {
    padding: 10px;
    padding-bottom: 50px;
  }

  .plans-container {
    flex-direction: column;
  }

  .plan-box {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}