.CreditCardContainer {
    @media (max-width: 768px) {
        height: 200px;
        margin-bottom: 20px;
    }
}

.CardDebit {
    width: 100%;
    height: 100%;   
}

.CardDebit>div {
    background-image: url("../../assets/card-images/6.jpeg") !important; 
    border-radius: 100px;
    width: 100% !important;
    height: 100% !important;
}

.rccs__card--front, .rccs__card--back {
    background-image: url("../../assets/card-images/6.jpeg") !important;
}

.rccs__card {
    height: 100% !important;
    width: 100% !important;
}

.ManageSubscriptionRightContainer {
    padding: 0 0 0 20px !important;
    @media (max-width: 768px) {
        padding: 0 !important;
    }
}

.AvailableDollarSetting {
    margin: 0;
}

.AddressText {
    @media (max-width: 768px) {
        margin: 0 0 5px 20px !important;
    }
}

.CardDebit {
    position: relative;
    margin: 20px auto;
  }
  
  .EditButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .CreditCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ManageSubscriptionRightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .AvailableDollarSetting {
    font-weight: bold;
    color: white;
  }
  
  .AddressBox {
    border: 1px solid #fff;
    border-radius: 6px;
    padding: 10px 25px;
    background: #212529;
  }
  